<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{
        $vuetify.lang.t("$vuetify.titles.new", [
          $vuetify.lang.t("$vuetify.plan.name")
        ])
      }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="formValid"
        class="my-10"
        lazy-validation
      >
        <v-row>
          <v-col
            style="margin-top: 10px"
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="newPlan.name"
              :label="$vuetify.lang.t('$vuetify.firstName')"
              :rules="formRule.firstName"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="newPlan.type"
              :items="plansType"
              chips
              :label="$vuetify.lang.t('$vuetify.tax.type')"
              item-value="value"
            >
              <template v-slot:selection="data">
                {{ $vuetify.lang.t('$vuetify.plan.type.' + data.item.value) }}
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ $vuetify.lang.t('$vuetify.plan.type.' + data.item.value) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="newPlan.is_active"
              :label="$vuetify.lang.t('$vuetify.plan.is_active')"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="newPlan.is_free"
              :disabled="newPlan.is_coupon"
              :label="$vuetify.lang.t('$vuetify.plan.is_free')"
              onchange=""
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="newPlan.general"
              :disabled="newPlan.is_coupon"
              :label="$vuetify.lang.t('$vuetify.plan.general')"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-switch
              v-model="newPlan.is_coupon"
              :label="$vuetify.lang.t('$vuetify.plan.use_coupon')"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="newPlan.currency"
              :items="currencies"
              chips
              :label="$vuetify.lang.t('$vuetify.currency')"
              item-text="name"
              item-value="value"
            />
          </v-col>
          <v-col
            style="margin-top: 10px"
            cols="12"
            md="3"
          >
            <v-text-field-integer
              v-model="newPlan.invoice_days"
              :label="$vuetify.lang.t('$vuetify.plan.invoice_period')"
              :properties="{
                hint: $vuetify.lang.t('$vuetify.hints.invoice_period')
              }"
            />
          </v-col>
          <v-col
            style="margin-top: 10px"
            cols="12"
            md="3"
          >
            <v-text-field-integer
              v-model="newPlan.subscribers_limit"
              :label="$vuetify.lang.t('$vuetify.plan.active_subscribers_limit')"
              :properties="{
                hint: $vuetify.lang.t('$vuetify.hints.active_subscribers_limit')
              }"
            />
          </v-col>
          <v-col
            style="margin-top: 10px"
            cols="12"
            md="3"
          >
            <v-text-field-integer
              v-model="newPlan.prorate_day"
              :label="$vuetify.lang.t('$vuetify.plan.prorate_day')"
              :properties="{
                hint: $vuetify.lang.t('$vuetify.hints.prorate_day')
              }"
            />
          </v-col>
          <v-col
            style="margin-top: 10px"
            cols="12"
            md="3"
          >
            <v-text-field-integer
              v-model="newPlan.test_day"
              :label="$vuetify.lang.t('$vuetify.plan.test_day')"
              :properties="{
                hint: $vuetify.lang.t('$vuetify.hints.test_day')
              }"
            />
          </v-col>
            <v-col
                cols="12"
                md="3"
            >
                <v-autocomplete
                    v-model="newPlan.platform_payment_gateway"
                    :items="platformType"
                    chips
                    multiple
                    auto-select-first
                    :label="$vuetify.lang.t('$vuetify.plan.platform_payment_gateway')"
                    item-value="value"
                >
                    <template v-slot:selection="data">
                        {{ $vuetify.lang.t('$vuetify.plan.platform.' + data.item.value) }}
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $vuetify.lang.t('$vuetify.plan.platform.' + data.item.value) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col
                v-if="newPlan.type === 'unicorn'"
                cols="12"
                md="3"
            >
                <v-autocomplete
                    v-model="newPlan.partner_payment_gateway"
                    :items="platformType"
                    chips
                    :label="$vuetify.lang.t('$vuetify.plan.partner_payment_gateway')"
                    item-value="value"
                >
                    <template v-slot:selection="data">
                        {{ $vuetify.lang.t('$vuetify.plan.platform.' + data.item.value) }}
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $vuetify.lang.t('$vuetify.plan.platform.' + data.item.value) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col
                v-if="newPlan.type === 'unicorn'"
                style="margin-top: 10px"
                cols="12"
                md="3"
            >
                <v-text-field-money
                    v-model="newPlan.partner_percent"
                    :label="$vuetify.lang.t('$vuetify.plan.partner_percent')"
                    :properties="{
                        hint: $vuetify.lang.t('$vuetify.hints.partner_percent')
                    }"
                />
            </v-col>
            <v-col
                v-if="newPlan.type === 'unicorn'"
                style="margin-top: 10px"
                cols="12"
                md="3"
            >
                <v-text-field-integer
                    v-model="newPlan.partner_permanent"
                    :label="$vuetify.lang.t('$vuetify.plan.partner_permanent')"
                    :properties="{
                hint: $vuetify.lang.t('$vuetify.hints.partner_permanent')
              }"
                />
            </v-col>
          <v-col
            v-if="newPlan.is_coupon"
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="newPlan.benefited"
              style="margin-top: 10px"
              :items="beneficiaries"
              :label="$vuetify.lang.t('$vuetify.plan.benefited')"
              :loading="isBeneficiaryTableLoading"
              auto-select-first
              item-text="firstName"
              return-object
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="$store.dispatch( 'beneficiary/toogleNewModal', true)"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>{{
                    $vuetify.lang.t('$vuetify.titles.newAction')
                  }}</span>
                </v-tooltip>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click="data.select"
                >
                  <v-avatar left>
                    <v-img
                      :src="
                        data.item.avatar ||
                          '/assets/avatar/avatar-undefined.jpg'
                      "
                    />
                  </v-avatar>
                  {{ data.item.email }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    <v-img
                      :src="
                        data.item.avatar ||
                          '/assets/avatar/avatar-undefined.jpg'
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.firstName }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ `${data.item.email}` }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="12" cols="12" v-if="!newPlan.general">
            <plan-countries-table :plan="newPlan" />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card>
              <v-card-title>{{ $vuetify.lang.t('$vuetify.plan.invoice_interval') }}/{{ $vuetify.lang.t('$vuetify.price') }}</v-card-title>
              <v-card-subtitle style="margin-top: 0px">
                {{ $vuetify.lang.t('$vuetify.hints.invoice_interval') }}
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.month"
                      :label="$vuetify.lang.t('$vuetify.plan.month')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.trimester"
                      :label="$vuetify.lang.t('$vuetify.plan.trimester')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.semester"
                      :label="$vuetify.lang.t('$vuetify.plan.semester')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.annually"
                      :label="$vuetify.lang.t('$vuetify.plan.annually')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.years_2"
                      :label="$vuetify.lang.t('$vuetify.plan.years_2')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.years_3"
                      :label="$vuetify.lang.t('$vuetify.plan.years_3')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.years_4"
                      :label="$vuetify.lang.t('$vuetify.plan.years_4')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field-money
                      v-model="newPlan.facture_period.years_5"
                      :label="$vuetify.lang.t('$vuetify.plan.years_5')"
                      required
                      :properties="{
                        prefix: newPlan.currency?newPlan.currency:'',
                        disabled: newPlan.is_free ===true
                      }"
                      :options="{
                        length: 15,
                        precision: 2,
                        empty: 0.00,
                      }"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="newPlan.description"
              :counter="250"
              maxlength="250"
              :label="$vuetify.lang.t('$vuetify.access.description')"
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-card>
              <v-card-subtitle>
                <v-switch
                  v-model="limit"
                  :label="$vuetify.lang.t('$vuetify.plan.time_limit')"
                />
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <app-datetime-picker
                      v-model="newPlan.starts_at"
                      :disabled="!limit"
                      :min-date="new Date()"
                      :label="
                        $vuetify.lang.t('$vuetify.plan.starts_at')
                      "
                      :clear-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :ok-text="$vuetify.lang.t('$vuetify.actions.accept')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <app-datetime-picker
                      v-model="newPlan.ends_at"
                      :disabled="!limit"
                      :min-date="newPlan.starts_at"
                      :label="
                        $vuetify.lang.t('$vuetify.plan.ends_at')
                      "
                      :clear-text="$vuetify.lang.t('$vuetify.actions.cancel')"
                      :ok-text="$vuetify.lang.t('$vuetify.actions.accept')"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <app-color-picker
              :value="newPlan.color"
              @input="inputColor"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="mb-2"
        :disabled="isActionInProgress"
        @click="cancelPlan"
      >
        <v-icon>mdi-close</v-icon>
        {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
      </v-btn>
      <v-btn
        :disabled="!formValid || isActionInProgress"
        :loading="isActionInProgress"
        class="mb-2"
        color="primary"
        @click="createNewPlan"
      >
        <v-icon>mdi-content-save</v-icon>
        {{ $vuetify.lang.t("$vuetify.actions.save") }}
      </v-btn>
    </v-card-actions>
    <new-beneficiary v-if="showNewModal" />
  </v-card>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import NewBeneficiary from '../../beneficiary/admin/NewBeneficiary'
import PlanCountriesTable from './plan-countries/PlanCountriesTable'

export default {
  name: 'NewPlan',
  components: { PlanCountriesTable, NewBeneficiary },
  data () {
    return {
      limit: false,
      dataCountry: [],
      countrySelect: null,
      formValid: false,
      hidePinCode1: true,
      hidePinCode2: true,
      errorPhone: null,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('plan', ['saved', 'newPlan', 'isActionInProgress']),
    ...mapState('beneficiary', ['beneficiaries', 'isBeneficiaryTableLoading', 'showNewModal']),
    ...mapGetters('statics', ['invoicePeriod', 'currencies', 'platformType', 'plansType'])
  },
  watch: {
    'newPlan.is_free': function () {
      this.newPlan.price = 0.00
    },
    'newPlan.price': function (newV) {
      if (this.newPlan.is_free) {
        newV = 0.00
        this.$store.state.plan.newPlan.price = 0.00
      }
    },
    'newPlan.use_coupon': function (newV) {
      if (this.newPlan.use_coupon) {
        this.getBeneficiariesAdmin()
      }
    }
  },
  async created () {
    this.formValid = false
    const countriesList = require('countries-list')
    Object.entries(countriesList.continents).forEach((v) => {
      this.dataCountry.push({
        prefix: v[0],
        name: v[1],
        continent: true
      })
      this.dataCountry.push({ divider: true })
      Object.fromEntries(Object.entries(countriesList.countries).filter(([key, value]) => {
        if (value.continent === v[0]) {
          this.dataCountry.push({
            data: value,
            continent: false
          })
        }
      }))
    })
    await this.getBeneficiariesAdmin()
    this.newPlan.platform_payment_gateway = this.platformType[0].value
  },
  methods: {
    ...mapActions('plan', ['createPlan', 'toogleNewModal']),
    ...mapActions('beneficiary', ['getBeneficiariesAdmin']),
    inputColor (color) {
      this.newPlan.color = color
    },
    customFilter (item, queryText, itemText) {
      return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    lettersNumbers (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    cancelPlan () {
      this.$router.push({ name: 'manager_plan' })
    },
    async createNewPlan () {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (!this.newPlan.is_free && parseFloat(this.newPlan.price).toFixed(2) === '0.00') {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.menu.admin_plan'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_zero_price'
              ),
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'red'
            })
        }
        if (this.newPlan.use_coupon &&
            (this.newPlan.subscribers_limit === null || parseFloat(this.newPlan.subscribers_limit).toFixed(2) === '0.00')) {
          this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.menu.admin_plan'),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_zero_active_subscribers_limit'
              ),
              icon: 'info',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'red'
            })
        }
        await this.createPlan(this.newPlan).then(() => {
          this.$router.push({ name: 'manager_plan' })
        })
      }
    }
  }
}
</script>

<style scoped></style>
